import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import {
  Subtitle,
  StyledEmail,
  InnerWrapper,
  mainBlue,
  lightBlue,
  mainWhite,
  screen,
} from "../components/common/variables";
import { FaChevronRight } from "react-icons/fa";
import IconMission from "../components/assets/icons/mission.svg";
import IconValue from "../components/assets/icons/value.svg";
import IconVision from "../components/assets/icons/vision.svg";

const Wrapper = styled.div`
  padding-top: 0;
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 70px;
  }

  .large-banner {
    display: none;
    @media ${screen.small} {
      display: block;
    }
  }

  .small-banner {
    display: block;
    @media ${screen.small} {
      display: none;
    }
  }

  .inner-wrapper {
    margin-top: 30px;
    @media ${screen.medium} {
      margin-top: 90px;
    }
  }

  .title {
    color: ${mainBlue};
    margin-bottom: 5px;
    @media ${screen.small} {
      margin-bottom: 15px;
    }
    @media ${screen.large} {
      margin-bottom: 20px;
    }
  }

  .subtitle-david {
    margin-bottom: 20px;
    display: none;
    @media ${screen.small} {
      display: block;
    }

    &--mobile {
      display: block;
      margin-bottom: 14px;
      @media ${screen.small} {
        display: none;
      }
    }
  }

  .main-container {
    &__content {
      max-width: 674px;
      color: ${mainBlue};
      h3,
      h4,
      h5,
      h6 {
        font-size: 1.18rem;
        font-weight: 400;
        margin-bottom: 0;
        @media ${screen.large} {
          font-size: 1.3rem;
          margin-bottom: 30px;
        }
      }
      p {
        font-size: 1rem;
        margin-bottom: 14px;
        @media ${screen.large} {
          font-size: 1.1rem;
          margin-bottom: 36px;
        }
      }

      strong {
        font-size: 1.18rem;
        @media ${screen.large} {
          font-size: 1.3rem;
          margin-bottom: 30px;
        }
      }
    }
  }

  .featured-list {
    margin-bottom: 30px;
    margin-top: 40px;
    @media ${screen.small} {
      margin-bottom: 120px;
      display: flex;
      margin-top: 90px;
    }
    @media ${screen.medium} {
    }

    &__each {
      color: ${mainBlue};
      padding: 25px 25px 40px 25px;
      @media ${screen.small} {
        padding: 0 30px 25px 30px;
        width: 33.33%;
      }
      @media ${screen.medium} {
        padding: 0 60px 25px 60px;
      }
      @media ${screen.large} {
        padding: 0 80px 25px 80px;
      }

      .icon {
        width: 130px;
        height: 130px;
        margin: 0 auto;
        @media ${screen.small} {
          width: 180px;
          height: 180px;
        }

        svg {
          width: 130px;
          height: 130px;
          margin: 0 auto;
          @media ${screen.small} {
            width: 180px;
            height: 180px;
          }
        }
      }

      .heading {
        font-size: 1.3rem;
        margin: 5px 0 0 0;
        @media ${screen.medium} {
          font-size: 2rem;
          margin: 10px 0 0 0;
        }
      }

      .sub-p {
        font-weight: 300;
        display: block;
        margin: 10px 0;
        font-size: 1rem;
        @media ${screen.large} {
          font-size: 1.1rem;
        }
      }

      p {
        font-size: 1rem;
        @media ${screen.large} {
          font-size: 1.1rem;
        }
      }

      .txt-list {
        white-space: pre;
        font-size: 1rem;
        @media ${screen.large} {
          font-size: 1.1rem;
        }

        .point {
          font-weight: 700;
          width: 32px;
          display: inline-block;
          line-height: 1.65;
        }

        .p {
        }
      }

      &--first {
        .icon {
          width: 120px;
          height: 120px;
          margin: 0 auto 22px auto;
          @media ${screen.small} {
            width: 180px;
            height: 180px;
            margin: 0 auto;
          }
          svg {
            width: 120px;
            height: 120px;
            @media ${screen.small} {
              width: 155px;
              height: 155px;
            }
          }
        }
      }

      &--mid {
        border-top: 2px #49a5dd solid;
        border-bottom: 2px #49a5dd solid;
        padding: 15px 25px 40px 25px;
        @media ${screen.small} {
          padding: 0 40px 25px 40px;
          border-left: 4px #49a5dd solid;
          border-right: 4px #49a5dd solid;
          border-top: none;
          border-bottom: none;
        }
        @media ${screen.medium} {
          padding: 0 70px 25px 70px;
        }
        @media ${screen.large} {
          padding: 0 80px 25px 80px;
        }
      }
    }
  }

  .contact-container {
    background-color: ${lightBlue};
    padding: 45px 0;
    @media ${screen.medium} {
      padding: 90px 320px 90px 0;
    }
    @media ${screen.large} {
      padding: 90px 0;
    }
    h6 {
      color: ${mainWhite};
      font-size: 1.3rem;
      @media ${screen.medium} {
        font-size: 2rem;
      }
    }
    p {
      color: ${mainWhite};
      margin-top: 20px;
      font-size: 1.1rem;
      font-weight: 300;
      @media ${screen.medium} {
        font-size: 1.5rem;
        margin-top: 10px;
      }
    }
    a {
      display: block;
      margin-top: 20px;
      margin-right: 15px;
      width: 100%;
      text-align: center;
      @media ${screen.xSmall} {
        display: inline-block;
        width: auto;
      }
      &:hover {
        background: ${mainWhite};
        color: ${lightBlue};
      }
    }
  }
`;

const MissionVisionValuesPage = ({ data }) => {
  const email = data.general.data.email_address;

  return (
    <Layout>
      <SEO
        title={"Mission, Vision & Values"}
        description={
          "The global ‘advice’ industry is undergoing significant change. Advisers stranded in defunct product-centric models are struggling to make the step toward a client-centric model."
        }
      />
      <Wrapper>
        <StaticImage
          className="large-banner"
          src="../images/global-adviser-alpha-helps-transform-advice-businesses-into-world-class-firms.jpg"
          alt="Transform advice businesses into world class firms"
          placeholder="blurred"
        />
        <StaticImage
          className="small-banner"
          src="../images/global-adviser-alpha-help-transform-advice-businesses-into-world-class-firms.jpg"
          alt="Transform advice businesses into world class firms"
          placeholder="blurred"
        />
        <InnerWrapper className="inner-wrapper">
          <Subtitle className="title" dark>
            Mission, Vision & Values (MVV)
          </Subtitle>
          <main className="main-container">
            <div className="main-container__content">
              <p>
                The global ‘advice’ industry is undergoing significant change.
                Advisers stranded in defunct product-centric models are
                struggling to make the step toward a client-centric model that
                not only delivers for clients but generates the financial and
                lifestyle benefits they seek for themselves.
              </p>
            </div>
          </main>

          <ul className="featured-list">
            <li className="featured-list__each featured-list__each--first">
              <div className="icon">
                <IconMission />
              </div>
              <h3 className="heading">Mission</h3>
              <span className="sub-p">(What we do every day)</span>
              <p>
                We help advisers win, by really helping their clients to win.
              </p>
            </li>
            <li className="featured-list__each featured-list__each--mid">
              <div className="icon">
                <IconVision />
              </div>
              <h3 className="heading">Vision</h3>
              <span className="sub-p">(What we are striving to become)</span>
              <p>
                To improve people’s lives, by enhancing global advisers value
                propositions one adviser at a time, and as a result, leave the
                world a better place.
              </p>
            </li>
            <li className="featured-list__each">
              <div className="icon">
                <IconValue />
              </div>
              <h3 className="heading">Values</h3>
              <span className="sub-p">
                (Values that are non-negotiable to us)
              </span>
              <div className="txt-list">
                <span className="point">P</span>{" "}
                <span className="p">PASSION FOR EXCELLENCE</span>
                <br />
                <span className="point">I</span>{" "}
                <span className="p">INTEGRITY</span>
                <br />
                <span className="point">L</span>{" "}
                <span className="p">LIFETIME OF LEARNING</span>
                <br />
                <span className="point">L</span>{" "}
                <span className="p">LEAD BY EXAMPLE</span>
                <br />
                <span className="point">A</span>{" "}
                <span className="p">A BALANCED LIFE</span>
                <br />
                <span className="point">R</span>{" "}
                <span className="p">RESPECT FOR OTHERS</span>
                <br />
              </div>
            </li>
          </ul>
        </InnerWrapper>
        <div className="contact-container">
          <InnerWrapper>
            <h6>Interested in this topic and want to know more?</h6>
            <p>Get in touch to have a chat with David</p>

            <StyledEmail
              obfuscateChildren={false}
              normal="true"
              email={email}
              headers={{
                subject: "Interested in Mission, Vision and Values",
              }}
            >
              CONTACT <FaChevronRight />
            </StyledEmail>
          </InnerWrapper>
        </div>
      </Wrapper>
    </Layout>
  );
};

export default MissionVisionValuesPage;

export const dataQuery = graphql`
  {
    general: prismicGeneralDetails {
      data {
        email_address
      }
    }
  }
`;
